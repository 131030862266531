export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section text-center">
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
        <h2>Professional Interests</h2>
          <div className="table-container">
            <table className="centered-table">
              <tbody>
                <tr>
                  <td>
                    <h3>Software Development</h3>
                    <p>Been picking up more Data Engineering and ML projects and trainings at Accenture and gaining lots of experience while having fun. The sheer number of python packages for a simple feature are my opponent.</p>
                  </td>
                  <td>
                    <h3>Entrepreneurship</h3>
                    <p>After shutting down 2 of my ventures, WizeUp and Interconnect Global, I am currently working on an idea for a new business that will hopefully come soon.</p>
                  </td>
                  <td>
                    <h3>Personal Development</h3>
                    <p>I am currently working in my free time on passion projects in AI to keep up with the latest technologies. I am also working on an idea of how I can help educate others in financial literacy that I soon hope to come to fruition.</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}
