import data from "../../data/index.json";

import React, { useEffect, useState } from 'react';

import './MySkills.css'; 



const MySkills = () => {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    // Simulating asynchronous behavior with setTimeout
    setTimeout(() => {
      setSkills(data.skills);
    }, 1000); // Replace with actual data fetching logic if needed
  }, []);

  return (
    <section className="section-page-section" id="MySkills">
      <div className="skills-list">
      <h2>My Skills</h2>
      {skills.map(skill => (
        <div key={skill.name}>
          <div className="skill-name">{skill.name}</div>
          <div className="progress-bar">
            <div className="progress" style={{ width: `${skill.percentage}%` }}>
              {skill.percentage}%
            </div>
          </div>
        </div>
      ))}
    </div>
    </section>
    
  );
};

export default MySkills;
