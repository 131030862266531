export default function HeroSection() {
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <p className="section--title">Hey, I'm Sanjay</p>
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">Full Stack Engineer</span>{" "}
            <br />
            <h2>Backend, Machine Learning, DevOps</h2>
          </h1>
          <p className="hero--section-description">
            I am a Full Stack Engineer focused on building scalable,
            highly availabile, highly secure, and robust cloud applications.
            <br /> I have 3 years of experience in software development from Accenture,
            consulting for 2 major financial services companies in the US. 
          </p>
        </div>
        {/* <a className="btn btn-primary" href="mailto:wizeupllc@gmail.com">Get In Touch</a> */}
      </div>
      <div className="hero--section--img">
        <img src="./img/about-me.png" alt="Hero Section" />
      </div>
    </section>
  );
}
